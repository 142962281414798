<template>
  <div class="footer-contact-info">
    <div class="container">
      <div class="row contact-info-row">
        <div class="col col-md-3 contact-clm">
          <div class="footer-clm">
            <div class="contacts-block" v-if="typeof contactData == 'object'">
              <h3 class="text-uppercase block-heading clm-title semiBold-15">
                {{ contactData.title }}
              </h3>
              <cmsBlockSimple
                v-if="typeof contactData == 'object'"
                :identifier="contactData.identifier"
              />
            </div>
          </div>
        </div>
        <div class="col col-md-5 general-info-clm">
          <div class="footer-clm">
            <h3 class="clm-title semiBold-15">{{ generalInfoFooter.title }}</h3>
            <div class="multiple-link-wrap">
              <v-runtime-template
                :class="['cms-block-content more']"
                :template="`<div>` + generalInfoFooter.content + `</div>`"
              ></v-runtime-template>
            </div>
          </div>
        </div>
        <div class="col col-md-4 logo-clm">
          <div class="footer-clm">
            <div class="block-content">
              <iframe
                name="f9edb7c5e01b68"
                width="100px"
                height="100px"
                data-testid="fb:page Facebook Social Plugin"
                title="fb:page Facebook Social Plugin"
                frameborder="0"
                allowtransparency="true"
                allowfullscreen="true"
                scrolling="no"
                allow="encrypted-media"
                src="https://www.facebook.com/v2.3/plugins/page.php?app_id=526535627357592&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df19f0d8eb07df58%26domain%3Dwww.topdesign.nl%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Fwww.topdesign.nl%252Ff32ed7713490a7c%26relation%3Dparent.parent&amp;container_width=339&amp;hide_cover=false&amp;href=https%3A%2F%2Fwww.facebook.com%2Fpages%2FTopdesignnl%2F136895726328016%3Ffref%3Dts&amp;locale=nl_NL&amp;sdk=joey&amp;show_facepile=true&amp;show_posts=false"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VRuntimeTemplate from "v-runtime-template";
import cmsBlockSimple from "@/base/core/components/common/BlockSimple.vue";
import getImageUrl from "@/base/helpers/getImageUrl.js";

export default {
  components: { VRuntimeTemplate, cmsBlockSimple },

  computed: {
    brands() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "footer_menu_quick_links"
      );
    },
    generalInfoFooter() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "general_info_footer"
      );
    },
    footerData() {
      // console.log("footer", [
      //   this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
      //     "footer_menu_information"
      //   ),
      //   this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
      //     "footer_menu_service"
      //   ),
      //   this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
      //     "footer_menu_quick_links"
      //   ),
      // ]);
      return [
        this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
          "footer_menu_information"
        ),
        this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
          "footer_menu_service"
        ),
        this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
          "footer_menu_quick_links"
        ),
      ];
    },
    contactData() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "footer_contact"
      );
    },
  },
  methods: {
    imgUrl(filename) {
      return getImageUrl(filename);
    },
  },
};
</script>

<style>
</style>