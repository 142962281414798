<template>
  <div class="m-link-wrap">
     <router-link :to="{ name: 'home' }" class="d-sm-none logo_mobile">
      <img :src="imgUrl('brand-white.png')" alt="" />
    </router-link>
    <div class="search-btn-m" @click="$emit('show-search-m')" :class="{ 'not-login': !isLoggedIn, login: isLoggedIn }">
      <b-icon icon="search"></b-icon>
    </div>
    <ul
      class="icon-link-wrap"
      :class="{ 'not-login': !isLoggedIn, login: isLoggedIn }"
    >
      <li v-for="icon of icons" :key="icon.iconNumber" class="top-menu"  
        @click.prevent="onClick(icon)"
        @mouseenter="onOver(icon)"
        @mouseleave="onLeave(icon)"
        >
        <b-link v-if="icon.isEnabled" :to="icon.link">
          <span class="icon">
            <ClientOnly>
              <i
                v-if="icon.isLinearIcon"
                class="fas"
                :class="icon.iconName"
              ></i>
              <div class="img" v-else>
                <b-img
                  :src="icon.iconName"
                  width="25"
                  height="25"
                  :alt="icon.text"
                  fluid
                ></b-img>
              </div>
            </ClientOnly>
            <b-badge v-if="icon.hasBadge" :variant="icon.badgeColor">{{
              icon.badgeValue
            }}</b-badge>
          </span>
          <span>{{ icon.text }}</span>
        </b-link>
        <HeaderIconsDropdownMenu
          v-if="
            icon.hasDropdown &&
            icon.menuId == 'account' &&
            icon.isEnabled == true
          "
          :icon="icon"
          :showType="showType"
          :visible="visibleIcons[icon.iconNumber]"
          @close="closeIconDropdown(icon)"
        />
        <HeaderIconsDropdownCart
          v-if="
            icon.hasDropdown && icon.menuId == 'cart' && icon.isEnabled == true
          "
          :icon="icon"
          :showType="showType"
          :visible="visibleIcons[icon.iconNumber]"
          @close="closeIconDropdown(icon)"
        />
        <HeaderIconsDropdown
        v-if="
          icon.hasDropdown &&
          icon.menuId == 'wishList' &&
          icon.isEnabled == true
        "
        :icon="icon"
        :showType="showType"
        :visible="visibleIcons[icon.iconNumber]"
        @close="closeIconDropdown(icon)"
      />
      </li>
    </ul>
  </div>
</template>
<script>
//import { mapGetters } from "vuex";
// import HeaderIconsDropdown from "./HeaderIconsDropdown.vue";
import HeaderIconsDropdown from "@/base/core/components/header/HeaderIconsDropdown.vue";
import HeaderIconsDropdownCart from "@/base/core/components/header/HeaderIconsDropdownCart.vue";
import HeaderIconsDropdownMenu from "@/base/core/components/header/HeaderIconsDropdownMenu.vue";
//import wishListAdapter from "@/esf_antarctica_feestenkleding/helpers/adapters/wishListAdapter";
import Cart from "@storefront/core/modules/cart/mixins";
// import infoIcon from "@/esf_antarctica_feestenkleding/assets/imgs/icon-info.png";
import ClientOnly from "vue-client-only";
import getImageUrl from "@/base/helpers/getImageUrl.js";

export default {
  name: "HeaderTopIcons",
  mixins: [Cart],
  components: {
    HeaderIconsDropdown,
    ClientOnly,
    HeaderIconsDropdownCart,
    HeaderIconsDropdownMenu,
  },
   data: () => ({
    visibleIcons: {},
  }),
  methods: {
    showIconDropdown(icon) {
      if (icon.hasDropdown && !this.visibleIcons[icon.iconNumber]) {
        this.$set(this.visibleIcons, icon.iconNumber, true);
      }
    },
    closeIconDropdown(icon) {
      if (icon.hasDropdown && this.visibleIcons[icon.iconNumber]) {
        this.$set(this.visibleIcons, icon.iconNumber, false);
      }
    },
    onClick(icon) {
      if (window.innerWidth <= 1024) {
        if (icon.hasDropdown && !this.visibleIcons[icon.iconNumber]) {
          this.showIconDropdown(icon);
        } else {
          this.closeIconDropdown(icon);
        }

        setTimeout(() => {
          this.closeIconDropdown(icon);
        }, 5000);
      }
    },

    onOver(icon) {
      if (window.innerWidth > 1024) {
        this.showIconDropdown(icon);
      }
    },
    onLeave(icon) {
      if (window.innerWidth > 1024) {
        this.closeIconDropdown(icon);
      }
    },
     imgUrl(fileName) {
      return getImageUrl(fileName);
    },
  },
  props: {
    showType: { type: String },
  },
  computed: {
    icons() {
      return [
        {
          iconNumber: 2,
          isLinearIcon: true,
          hasBadge: false,
          iconName: "fa-phone-alt",
          text: "+31(0)12-3456789",
          hasDropdown: false,
          link: "/contact",
          isEnabled: true,
        },
        {
          iconNumber: 4,
          isLinearIcon: true,
          hasBadge: true,
          badgeValue: this.wishlistCount,
          badgeColor: "success",
          // iconName: "fa-heart",
          iconName: "fa-heart",
          text: this.$t("wishlist"),
          hasDropdown: true,
          menuId: "wishList",
          isEnabled: this.isLoggedIn,
          dropHeading: this.$t("items_in_wishlist", {
            numberOf: this.wishlistCount,
          }),
          dropItems: this.wishlistItems,
        },
        {
          iconNumber: 5,
          isLinearIcon: true,
          hasBadge: false,
          iconName: "fa-user-alt",
          text: this.$t("login"),
          hasDropdown: false,
          link: "/login",
          isEnabled: !this.isLoggedIn,
        },
        {
          iconNumber: 6,
          isLinearIcon: true,
          hasBadge: false,
          iconName: "fa-user-alt",
          text: this.$t("account"),
          menuId: "account",
          hasDropdown: true,
          isEnabled: this.isLoggedIn,
        },
        {
          iconNumber: 3,
          isLinearIcon: true,
          hasBadge: true,
          badgeValue: this.cartCount,
          badgeColor: "success",
          iconName: "fa-shopping-basket",
          text: "Winkelmand",
          isEnabled: true,
          hasDropdown: true,
          dropHeading: this.$t("items_in_cart", { numberOf: this.cartCount }),
          dropItems: this.cartItems,
          menuId: "cart",
          cartFooter: {
            totalPrice: this.cartSubTotalPrice,
            btnText: this.$t("goto_checkout"),
            btnVariant: "success",
          },
          link: "#",
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
