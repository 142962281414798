var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header-text-wrap"},[_vm._m(0),_c('div',{staticClass:"top-header isMobile sm-header-slider"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-12"},[_c('ClientOnly',[_c('div',{staticClass:"header-fade-slider"},[_c('div',{staticClass:"header-slider-wrap"},[_c('VueSlickCarousel',{attrs:{"arrows":false,"dots":false,"slidesToShow":3,"autoplay":false,"responsive":[
                  {
                    breakpoint: 1199,
                    settings: {
                      slidesToShow: 2,
                    },
                  },
                  {
                    breakpoint: 767,
                    settings: {
                      slidesToShow: 2,
                    },
                  },
                  {
                    breakpoint: 560,
                    settings: {
                      slidesToShow: 1,
                      autoplay:true
                    },
                  } ]}},_vm._l((_vm.informativeSlider.slides),function(slide,index){return _c('div',{key:index,staticClass:"cms-block-content"},[_c('div',{domProps:{"innerHTML":_vm._s(slide.description)}})])}),0)],1)])])],1)])])]),_c('div',{staticClass:"header-text isDesktop top-header"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-md-12"},[_c('HeaderTopIcons',{attrs:{"showType":"1"},on:{"show-search-m":function($event){return _vm.handleSearchMobile(true)}}})],1)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header-text isMobile"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row text-row"},[_c('div',{staticClass:"col col-md-12"},[_c('p',{staticClass:"mb-0 regular-15 text-center"},[_vm._v(" Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. ")])])])])])}]

export { render, staticRenderFns }