<template>
  <div class="middle-header d-none d-sm-block">
    <div class="container">
      <div class="row logo-row">
        <div class="col-12 col-md-12">
          <div class="navbar-brand">
            <router-link :to="{ name: 'home' }">
              <img :src="imgUrl('brand.png')" alt="" />
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import getImageUrl from "@/base/helpers/getImageUrl.js";

export default {
  components: {},

  data() {
    return {
      carouselConf: {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,

        responsive: [
          {
            breakpoint: 600,
            settings: {
              autoplay: true,
              fade: true,
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: false,
            },
          },
        ],
      },
    };
  },
  methods: {
    imgUrl(fileName) {
      return getImageUrl(fileName);
    },
  },
};
</script>


<style>
</style>