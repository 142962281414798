<template>
  <footer id="Footer" class="footer">
    <at-footer-services></at-footer-services>
    <at-footer-news-latter></at-footer-news-latter>
    <at-footer-contact></at-footer-contact>
    <at-footer-catagory></at-footer-catagory>
    <at-footer-payment></at-footer-payment>
    <at-footer-copy-right></at-footer-copy-right>
  </footer>
</template>

<script>
import AtFooterServices from "@/base/core/components/footer/FooterServices.vue";
import AtFooterNewsLatter from "@/base/core/components/footer/FooterNewsLatter.vue";
import AtFooterContact from "@/esf_antarctica_topdesign/core/components/footer/FooterContact.vue";
import AtFooterCatagory from "@/base/core/components/footer/FooterCatagory.vue";
import AtFooterPayment from "@/base/core/components/footer/FooterPayment.vue";
import AtFooterCopyRight from "@/esf_antarctica_topdesign/core/components/footer/FooterCopyRight.vue";

export default {
  components: {
    AtFooterServices,
    AtFooterNewsLatter,
    AtFooterContact,
    AtFooterCatagory,
    AtFooterPayment,
    AtFooterCopyRight,
  },
};
</script>

<style>
</style>