<template>
  <div class="header-text-wrap">
    <div class="header-text isMobile">
      <div class="container">
        <div class="row text-row">
          <div class="col col-md-12">
            <p class="mb-0 regular-15 text-center">
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
              commodo ligula eget dolor.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="top-header isMobile sm-header-slider">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-12">
            <ClientOnly>
              <div class="header-fade-slider">
                <div class="header-slider-wrap">
                  <VueSlickCarousel :arrows="false" :dots="false" :slidesToShow="3" :autoplay="false" :responsive="[
                    {
                      breakpoint: 1199,
                      settings: {
                        slidesToShow: 2,
                      },
                    },
                    {
                      breakpoint: 767,
                      settings: {
                        slidesToShow: 2,
                      },
                    },
                    {
                      breakpoint: 560,
                      settings: {
                        slidesToShow: 1,
                        autoplay:true
                      },
                    },
                  ]">
                    <div class="cms-block-content" v-for="(slide, index) in informativeSlider.slides" :key="index">
                      <div v-html="slide.description"></div>
                    </div>
                  </VueSlickCarousel>
                </div>
              </div>
            </ClientOnly>
          </div>
        </div>
      </div>
    </div>
    <div class="header-text isDesktop top-header">
      <div class="container">
        <div class="row">
          <div class="col col-md-12">
            <HeaderTopIcons showType="1" @show-search-m="handleSearchMobile(true)" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderTopIcons from "@/esf_antarctica_topdesign/core/components/header/HeaderTopIcons.vue";
import VueSlickCarousel from "vue-slick-carousel";
import ClientOnly from "vue-client-only";

export default {
  components: {
    HeaderTopIcons,
    VueSlickCarousel,
    ClientOnly
  },
  computed: {
    informativeSlider() {
      const data =
        this.$store.getters["sliders/getSliderByIdentifier"](
          "informative_slider"
        );
      return data;
    },
  },
};
</script>

<style>
</style>